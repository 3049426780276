import axios from 'axios'

export class PipedriveHttp {
    constructor(Vue, options = {}) {
        this.options = options
    }

    async authorize(authorizationCode) {
        try {
            const response = await axios.post(
                `${this.options.authorizeEndpoint}`,
                {
                    "grant_type": "authorization_code",
                    "code": authorizationCode,
                    "redirect_uri": this.options.appCallbackUrl
                },
            )
            return response.data
        } catch (error) {
            // Deauthenticate
            console.log(error)
            return Promise.resolve({ error })
        }
    }

    async me() {
        try {
            const response = await axios.get(
                `${this.options.meEndpoint}`
            )
            return response.data
        } catch (error) {
            console.log(error)
            return Promise.resolve({ error })
        }
    }
}

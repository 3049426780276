<template>
  <div class="search">

      <div class="field icon-left">
        <input id="search" name="search" class="" placeholder="Indtast søgning" type="search" autocomplete="off"
          v-model="searchQuery"
          @keyup="search"  @focus="search"  @blur="reset"
        />
        <Svgicon icon="search" class="icon"/>
      </div>

      <div class="popover" :class="{'popover--open' : showSearchResultContainer }" tabindex="0">

          <div class="popover__panel">

            <div class="popover__close" @click="showSearchResultContainer = false"><Svgicon icon="cross" /></div>


            <LocalSpinner v-if="spinner_active" :active="spinner_active"/>

            <div  v-if="searchQuery.length < 1" class="popover__placeholder">
              <p>Find det du søger, ved at indtaste de første bogstaver i søgefeltet her ovenover.</p>
              <img src="@/assets/search_illustration.svg" alt="Workflow"/>
            </div>


            <div v-else-if="!contractors.length && !quotes.length && !spinner_active" class="no_results">
              <h5 class="popover__title">Ingen resultater</h5>
            </div>


            <nav v-else aria-label="Directory anim_list" >

              <div v-if="quotes.length">
                <h5 class="popover__title">Tilbud</h5>
                <ul class="items">
                  <li v-for="quote in quotes" :key="quote.id" class="item item--column">
                          <router-link :to="{path:`/quotes/edit/${quote.id}`, force: true}" class="item__link" @blur="reset">
                            <p class="">{{ quote.title }}</p>
                            <template v-if="quote.state">
                              <StatusBadge class="item__status" :showDot="false" size="small" :status="quote.status" :active="true"></StatusBadge>
                            </template>
                          </router-link>
                  </li>
                </ul>
              </div>

              <div v-if="contractors.length">
                <h5 class="popover__title">Håndværkere</h5>
                <ul class="items">
                  <li v-for="contractor in contractors" :key="contractor.id" class="item">
                          <router-link :to="`/contractor/${contractor.id}`" class="item__link" @blur="reset">
                             <img v-if="contractor.logoUrl" class="inline__image" :src="contractor.logoUrl" alt=""/>
                             <img v-else class="inline__image" src="@/assets/client_fallback_img.png" alt=""/>
                            <p class="">{{ contractor.name }}</p>
                          </router-link>
                  </li>
                </ul>
              </div>

            </nav>

        </div>

      </div>

  </div>
</template>

<script>
import { ref } from "vue";
import StatusBadge from "@/components/layout/StatusBadge";



export default {
  components: {
    StatusBadge
  },
  data() {
    return {
      searchQuery: "",
      searchQueryPrevious: "",
      showSearchResultContainer: false,
      quotes: [],
      contractors: [],
      spinner_active: false,
    };
  },


  methods: {


    reset(e) {

      if( e.relatedTarget !== null && e.relatedTarget.classList.contains('item__link')) return false;
      this.showSearchResultContainer = false;

    },

    async search() {

      this.showSearchResultContainer = true;

      if( this.searchQuery.length > 0 && this.searchQueryPrevious !== this.searchQuery) {

        this.spinner_active = true;

        let quotes      = await this.$store.dispatch('quotes/getQuotes', {size: 5, query: this.searchQuery})
        let contractors = await this.$store.dispatch('contractors/getContractors', {size: 5, query: this.searchQuery})

        if( quotes.content.length ) {
          this.quotes = quotes.content;
        } else {
          this.quotes = [];
        }

        if( contractors.content.length ) {
          this.contractors = contractors.content;
        } else {
          this.contractors = [];
        }


        this.spinner_active = false;

      }

      this.searchQueryPrevious = this.searchQuery;

    }

  },


};
</script>

<style scoped lang="scss">

.search {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  perspective: 1200px;

  @include medium {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    justify-content: center;
  }


  .field {
    max-width: 330px;
    margin: 0;
    input {
      background: color(color6);
      border-color: color(color6);
    }
    .icon {
      color: color(color3);
    }

    &:focus {
      .icon {
        color: color(color1);
      }
    }
  }

}

.popover {
    outline: none;
    position: absolute;
    box-shadow: var(--shadow3);
    border-radius: $radius;
    background: color(white);
    right: 0;
    min-width: 250px;
    max-width: 400px;
    width: 100%;
    top: 55px;
    opacity: 0;
    transform: translateY(-5px) rotateX(30deg);
    transition: 0.1s $easeInOutSine;
    border: 1px solid rgba(black, 0.1);

    pointer-events: none;
    visibility: hidden;
    min-height: 200px;

    p {
      margin: 0;
    }




    &__placeholder {
      p {
        width: 50%;
        font-size: 0.9rem;
        opacity: 0.5;
        padding: 40px 0px;
      }
      img {
        width: 190px;
        display: block;
        padding: 20px;
        position: absolute;
        height: auto;
        bottom: -45px;
        right: 0px;
        z-index: 3;
      }

    }

    .no_results {
      padding-top: 70px;
      text-align: center;
      opacity: 0.5;
    }

    &__panel {
      padding: 1rem;
    }

    &__bottompanel {
      padding: 1rem;
      background: color(color6);
    }

    &__title {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0.3rem;
    }

    &__historyitem {
      padding-top: 0.2rem;
      color: color(color8);
      span:first-of-type {
        margin-right: 10px;
      }
    }

    &--open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;
      transform: translateY(0px) rotateX(0deg);
    }

      &__close {
        border-radius: 100px;
        background: rgba(white, 0.4);
        box-shadow: var(--shadow2);
        position: absolute;
        top: 15px;
        right: 15px;
        padding:8px;
        backdrop-filter: blur(4px);
        cursor: pointer;
        transition: 0.25s ease;
        &:hover {
          box-shadow: var(--shadow3);
        }

      .svgicon {
        width: 23px;
        display: block;
      }
    }

}

.items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  .item {

    border-bottom: 1px solid color("color11", $alpha: -0.5);

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.4rem 0 0.4rem 0;
      border-radius: $radius;
      &:hover {
        background: color(color6);
      }
    }

    &--column a {
      flex-direction: column;
      align-items: flex-start;
    }

  }


  img {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 100px;
    overflow: hidden;
    object-fit: cover;
    margin-right: 15px;
    box-shadow: var(--shadow3);
  }
}


</style>

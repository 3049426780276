import globalApi from '../../api/global'

// state
const state = () => ({
    trades: null,
    counties: null,
    portals: null,
})

// getters
const getters = {
  getTrades(state) {
      return state.trades
  },
  getCounties(state) {
    return state.counties
  },
  getPortals(state) {
    return state.counties
  },
}

// actions
const actions = {
    async getTrades({commit, getters}) {
       if( getters.getTrades == null ) {
          var response = await globalApi.getTrades();
          commit('setTrades', response.data.data)
        }
    },

    async getPortals({commit, getters}) {
      if( getters.getPortals == null ) {
         var response = await globalApi.getPortals();
         commit('setPortals', response.data.data)
       }
   },

    async searchTrades({commit}, query) {
      var response = await globalApi.getTrades(query);
      commit('setTrades', response.data.data)
    },

    async getCounties({commit, getters}) {
      if( getters.getCounties == null ) {
         var response = await globalApi.getCounties();
         commit('setCounties', response.data.data)
       }
   },
}

// mutators
const mutations = {
  setTrades(state, trades) {
      state.trades = trades
  },
  setCounties(state, counties) {
    state.counties = counties
  },
  setPortals(state, portals) {
    state.portals = portals
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

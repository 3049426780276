import { createApp } from 'vue'
import App from './App.vue'

import './assets/scss/global.scss'

import authentication from './plugins/authentication'
import pipedrive from './plugins/pipedrive'
import router from './router'
import store from './store'

import mixins from './mixins'
import notifcations from './plugins/notifications'

import Default from "./layouts/Default"
import Blank from "./layouts/Blank"

import Svgicon from "./components/Svgicon"
import LocalSpinner from './components/LocalSpinner.vue'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import VCalendar from 'v-calendar';
import Multiselect from '@vueform/multiselect'
import CurrencyInput from './components/CurrencyInput'
import AnimatedNumber from './components/AnimatedNumber'
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import VueApexCharts from "vue3-apexcharts";
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();

const IS_DEVELOPMENT = (process.env.NODE_ENV == 'development')

var app = createApp(App)
    .use(store)
    .use(router)

    .use(notifcations)
    .use(VCalendar, {
      screens: {
        custom_xl: '1300px',
      },
    })
    .use(authentication,
      Object.assign({
        router: router,
        hostname: `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`,
        tokenStore: [
          'localStorage'
        ],
        tokenDefaultName: 'hi5_t',
        userDefaultName: 'hi5_u',
        callback: () => {
          // console.log(createApp)
        }
      }, IS_DEVELOPMENT ? {
        loginAuth: {
          header: 'Authorization',
          type: "Basic",
          clientId: process.env.VUE_APP_AUTH_LOGIN_AUTH_CLIENT,
          clientSecret: process.env.VUE_APP_AUTH_LOGIN_AUTH_SECRET,
        }
      } : {})
    )
    .use(pipedrive, {
      router: router,
      tokenStore: [
        'localStorage'
      ],
      appClientId: process.env.VUE_APP_PIPEDRIVE_APP_CLIENT_ID,
      appClientSecret: process.env.VUE_APP_PIPEDRIVE_APP_CLIENT_SECRET,
      appCallbackUrl: process.env.VUE_APP_PIPEDRIVE_APP_CALLBACK_URL,
      appInstallUrl: process.env.VUE_APP_PIPEDRIVE_APP_INSTALL_URL,
      authorizeEndpoint: process.env.VUE_APP_PIPEDRIVE_APP_AUTHORIZE_URL,
      meEndpoint: process.env.VUE_APP_PIPEDRIVE_APP_ME_URL,
    })
    .use(Toast, {
      position: "top-right",
      transition: "Vue-Toastification__slideBlurred",
      timeout: 4000,
      filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter(
          t => t.type === toast.type
        ).length !== 0) {
          // Returning false discards the toast
          return false;
        }
        // You can modify the toast if you want
        return toast;
      }
    })
    .use(VueSvgInlinePlugin)
    .use(VueApexCharts);


app.mixin(mixins)
app.component('default-layout', Default)
app.component('blank-layout', Blank)

app.component('Svgicon', Svgicon)
app.component('LocalSpinner', LocalSpinner)
app.component('Multiselect', Multiselect)
app.component('CurrencyInput', CurrencyInput)
app.component('AnimatedNumber', AnimatedNumber)
app.component('LottieAnimation', LottieAnimation)


app.mount('#app')

import 'v-calendar/dist/style.css';
import "vue-multiselect/dist/vue-multiselect.css";




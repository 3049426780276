// const Authentication = {
//     install(Vue, options) {
//         Vue.mixins({
//             mounted() {
//                 console.log('Hi5 Authentication initialized');
//             },
//         })
//     }        
// }

// export default Authentication;

import { Auth } from './auth.js'

let installed = false

export default {
  install(Vue, options) {
    var is_development = process.env.NODE_ENV === 'development'
    if (installed) return

    const auth = new Auth(Vue, options);

    Vue.config.globalProperties.$auth = auth

    installed = true
    
    // if (is_development) console.log('Hi5 Authentication installed.', auth)
  }
}
import contractorsApi from '../../api/contractors'


// state
const state = () => ({
  contractor: null,
  contractError: null,
  contractorQuotes: null,
  page: null,
})

// getters
const getters = {
  getContractor(state) {
    return state.contractor
  },
  getContractorFieldByIndex: (state) => (idx) => {
    return state.contractor.fields[idx]
  },
  getContractorQuotes(state) {
    return state.contractorQuotes
  },

  getPage(state) {
    return state.page
  },

}

// actions
const actions = {

  async search({ commit }, query) {
    var response = await contractorsApi.search(query);
    return response.data.data
  },


  async getContractorQuotes({ commit }, params) {
    commit('setContractorQuotes', null)

    if (typeof params == 'undefined') params = {}
    var id = (typeof params.id != 'undefined' && params.id != null) ? params.id : 1
    var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 15
    var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0
    var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
    var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

    if( page > 0) page = page - 1 // Make zero based,

    try {
      var response = await contractorsApi.getQuotes(id, page, size, sort, query);

      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setContractorQuotes', response.data.data)
        return response.data.data
      } else {
        commit('setContractorQuotes', null)
      }

    } catch (err) {
      console.log('getContractorById', err)
      commit('setContractorQuotes', null)
    }

  },

  async getContractorById({ commit }, params) {
    commit('setContractor', null)
    try {
      var response = await contractorsApi.getById(params.id);

      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setContractor', response.data.data)
        return response.data.data
      } else {
        commit('setContractor', null)
        commit('setContractorError', response.data.data)
      }

    } catch (err) {
      console.log('getContractorById', err)
      commit('setContractor', null)
    }

  },


  async getContractorPage({ commit, dispatch }, params) {
    let contractors  = await dispatch('getContractors', params)
    commit('setPage', contractors)
  },

  async getContractors({ commit }, params) {
    if (typeof params == 'undefined') params = {}
    var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 15
    var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0
    var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
    var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

    var active = null;
    if(params.active === true || params.active === 'true') active = true;
    if(params.active === false || params.active === 'false') active = false;

    if( page > 0) page = page - 1 // Make zero based,

    var response = await contractorsApi.getContractors(page, size, sort, query, active)

    return response.data.data;
  },


  async createContractorInEconomic({ commit }, params) {
    var response = await contractorsApi.createContractorInEconomic(params);
    return response.data;
  },

  async overwriteAccountNumber({ commit }, params) {
    var response = await contractorsApi.overwriteAccountNumber(params);
    return response.data;
  },


  async mailchimpSubscription({ commit }, params) {
    var response = await contractorsApi.mailchimpSubscription(params);
    return response.data;
  },

}


// mutators
const mutations = {
  setContractor(state, contractor) {
    state.contractor = contractor
  },

  setContractorError(state, error) {
    state.contractorError = error
  },

  setPage(state, page) {
    state.page = page
  },

  setContractorQuotes(state, quotes) {
    state.contractorQuotes = quotes
  },


}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

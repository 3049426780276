import { Pipedrive } from './pipedrive'

let installed = false

export default {
    install(Vue, options) {
        var is_development = process.env.NODE_ENV === 'development'
        if (installed) return

        const pipedrive = new Pipedrive(Vue, options);

        Vue.config.globalProperties.$pipedrive = pipedrive

        installed = true
    }
}
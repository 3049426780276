import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {

    async getTrades(query) {
        var q = (query) ? '?query=' + query : '';
        try {
            return await axios.get(
                `${hostname}/trades${q}`,
                config
                )
        } catch (error) {
            console.log(error)
        }
    },

    async getCounties(query) {
      try {
          return await axios.get(
              `${hostname}/counties`,
              config
              )
      } catch (error) {
          console.log(error)
      }
    },

    async getPortals(query) {
        try {
            return await axios.get(
                `${hostname}/portals`,
                config
                )
        } catch (error) {
            console.log(error)
        }
      },

}

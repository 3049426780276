import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {

    async getReminders(page, size, from, to, sort, query) {

      from = new Date( parseInt(from) ).setUTCHours(0,0,0,0)
      to   = new Date( parseInt(to) ).setUTCHours(24,0,0,0)

      let url = `${hostname}/accounting/invoices/overdue/?page=${page}&size=${size}&from=${from}&to=${to}`

      url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'
      if( query ) url += `&query=${query}`

      try {
        return await axios.get(
          url,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },



    async getReport(dateRangeFrom, dateRangeTo, dateRangeState, dateRangeStatus, filterCurrentStatuses, filterConsultantIds, filterQuoteTypes, page, size, sort, query) {

     dateRangeFrom = new Date( parseInt(dateRangeFrom) ).setUTCHours(0,0,0,0)
     dateRangeTo   = new Date( parseInt(dateRangeTo) ).setUTCHours(24,0,0,0)

      let url = `${hostname}/accounting/quotes/report/?page=${page}&size=${size}&dateRangeFrom=${dateRangeFrom}&dateRangeTo=${dateRangeTo}`

      url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'

      url += ( dateRangeStatus ) ? `&dateRangeStatus=${dateRangeStatus}` : '&dateRangeStatus=SENT'; // SENT
      url += ( dateRangeState ) ? `&dateRangeState=${dateRangeState}` : '&dateRangeState=ACTIVE'; // ACTIVE

      if( filterCurrentStatuses && filterCurrentStatuses != '' )   url += `&filterCurrentStatuses=${filterCurrentStatuses}` // SENT,PAID
      if( filterConsultantIds && filterConsultantIds != '' )     url += `&filterConsultantIds=${filterConsultantIds}` // 146,145
      if( filterQuoteTypes && filterQuoteTypes != '')        url += `&filterQuoteTypes=${filterQuoteTypes}` // RENEGOTIATION, CANVAS

      if( query ) url += `&query=${query}`

      try {
        return await axios.get(
          url,
          config
        )
      } catch (error) {
        console.log(error)

      }
    },

    async getStatistics(from, to, status, filterCurrentStatuses, filterConsultantIds, filterQuoteTypes) {

      from = new Date( parseInt(from) ).setUTCHours(0,0,0,0)
      to   = new Date( parseInt(to) ).setUTCHours(24,0,0,0)

      let url = `${hostname}/accounting/quotes/report/statistics/?&dateRangeState=ACTIVE&dateRangeFrom=${from}&dateRangeTo=${to}&dateRangeStatus=${status}`


      if( filterCurrentStatuses && filterCurrentStatuses != '' )   url += `&filterCurrentStatuses=${filterCurrentStatuses}` // SENT,PAID
      if( filterConsultantIds && filterConsultantIds != '' )     url += `&filterConsultantIds=${filterConsultantIds}` // 146,145
      if( filterQuoteTypes && filterQuoteTypes != '')        url += `&filterQuoteTypes=${filterQuoteTypes}` // RENEGOTIATION, CANVAS

      try {
        return await axios.get(
          url,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },


}

export default {

  data() {
    return {
      statuses: {
        UNDEFINED: 'Kladde',
        SENT: 'Sendt',
        ACCEPTED: 'Accepteret',
        INVOICED: 'Faktureret',
        PAID: 'Betalt',
        CANCELLED: 'Annulleret',
        PARTIALLY_CANCELLED: 'Delvist annulleret',
        REJECTED: 'Afvist',
        INSTALLMENT_PLAN: 'Ratebetaling',
        COLLECTION_INTERNAL: 'Intern inkasso',
        COLLECTION_INTERNAL_INSTALLMENT_PLAN: 'Afdragsordning',
        COLLECTION_EXTERNAL: 'Ekstern inkasso',
        COLLECTION_EXTERNAL_CANCELLED: "Annulleret ekstern inkasso",
        COLLECTION_EXTERNAL_PARTIALLY_CANCELLED: "Delvist annulleret ekstern inkasso",
        COLLECTION_EXTERNAL_PAID: "Betalt ekstern inkasso",
        COLLECTION_EXTERNAL_INSTALLMENT_PLAN: "Afdragsordning ekstern inkasso",
        INVOICE_PENDING: 'Afvent fakturering',
      },
    };
  },

  methods: {

    formatCurrency(value, fractions) {
      var fraction = ( fractions !== 'undefined' || fractions !== null) ? fractions : 1;
      var formatter = new Intl.NumberFormat("da-DK", {
        minimumFractionDigits: fraction
      });
      return formatter.format(value);
    },

    formatDate(value) {

      var d = new Date(value).toLocaleString('da-DK', {
        dateStyle: 'long',
      })
      return d;
    },

    formatDateShort(value) {

      var d = new Date(value).toLocaleString('da-DK', {
        dateStyle: 'short',
      })
      return d;
    },

    formatDateTime(value) {

      var d = new Date(value).toLocaleString('da-DK', {
        dateStyle: 'long',
        timeStyle: 'short'
      })
      return d;
    },

    daysAgo(date) {
      var now = new Date();
      var then = new Date(date);
      var diff = now - then;
      var days = Math.floor(diff / 1000 / 60 / 60 / 24);
      return days;
    },


    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    validatePhone(tel) {
      var re = /\d/;
      return re.test(tel);
    },

    slugify(value) {

      if( !value ) return value;
      value = value.replace(/^\s+|\s+$/g, ''); // trim
      value = value.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;øåæ";
      var to   = "aaaaeeeeiiiioooouuuunc------oaa";
      for (var i=0, l=from.length ; i<l ; i++) {
          value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      value = value.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

      return value;
    },

    linkify(text) {
      if( text == undefined) return text;

      var urlRegex =/([^\s>,(]+(\.com|\.dk|\.no|\.se|\.fi)([^\s,.)< ]+)?)/ig;

      return text.replace(urlRegex, function(url) {
          // If first part of url is not http or https, add http://
          let properurl = url;
          if (url.split(' ')[0].split(':')[0] !== 'http' && url.split(' ')[0].split(':')[0] !== 'https') {
              properurl = 'https://' + url;
          }
          return '<a class="linkify" href="' + properurl + '" target="_blank">' + url + '</a>';
      });
    },

    // Check if the status is past certain statuses
    // Returns bool
    // eg. statusReached('INVOICED', 'ACCEPTED') => true
    statusReached(current_status, destination) {

      // If current_status reached and drove past destination => return true
      let res = false;
      let startCheckingCurrent = false
      for (const [key, value] of Object.entries(this.statuses)) {

        if(startCheckingCurrent == false && destination == key) {
          startCheckingCurrent = true;
        }

        if( startCheckingCurrent  && current_status == key) {
          res = true;
          break;
        }

      }
      return res
    },

    formatAddress(value) {

      var address = value;
      var addressArray = address.split(',')
      var res = '';
      // Loop addressArray
      for (var i = 0; i < addressArray.length; i++) {
        res += addressArray[i] + '<br>';
      }
      return res;
    },

    formatSalesTier(value) {
      var res = 'Standard';
      if( value == 'STANDARD' )   res = 'Standard';
      if( value == 'SILVER' )     res = 'Sølv';
      if( value == 'GOLD' )       res = 'Guld';
      if( value == 'PLATINUM' )   res = 'Platin';
      return res;
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    // Used on pages/quote component/quote
    showItemValues(field, item_index) {

      if( field.elements == undefined  ) return '-';
      if( !field.elements.length ) return '-';

      let values = '';


      field.elements.forEach((elm, elm_index) => {
        let value = this.info.items[item_index].data[elm.elementId]

        if( field.type == 'TEXT_SHORT')       values = value
        if( field.type == 'NUMBER')           values = value
        if( field.type == 'TEXT_LONG')        values = ( value ) ? '<p class="pre_formatted">' + value + '</p>' : '';
        if( field.type == 'DROPDOWN_SINGLE')  values = ( value !== null ) ? value.value : '';
        if( field.type == 'TRADES_SINGLE')    values = ( value !== null ) ? value.name : '';
        if( field.type == 'REGIONS_SINGLE')   values = ( value !== null ) ? value.name : '';
        if( field.type == 'PORTALS_SINGLE')   values = ( value !== null ) ? this.capitalize(value.name) : '';
        if( field.type == 'DATE')             values = this.formatDate(value)

        if( field.type == 'DATE_RANGE') {

          // Sort by element ID, as the date range start and from are not always in the same order
          field.elements.sort((a, b) => (a.id > b.id) ? 1 : -1)

          values += ( value ) ? this.formatDate(value) : '';
          if( elm_index == 0 && value ) values += ' - '
        }

        if( field.type == 'TRADES_MULTIPLE') {
          if( typeof value !== 'object') return;
          if ( value.length ) {
            value.forEach( (trade, trade_index) => {
              if( trade_index !== 0) values += ', '
              values += trade.name
            })
          }
        }

        if( field.type == 'PORTALS_MULTIPLE') {
          if( typeof value !== 'object') return;
          if ( value.length ) {
            value.forEach( (portal, portal_index) => {
              if( portal_index !== 0) values += ', '
              values += this.capitalize(portal.name)
            })
          }
        }

        if( field.type == 'DROPDOWN_MULTIPLE') {
          if( typeof value !== 'object') return;
          if ( value.length ) {
            value.forEach( (option, option_index) => {
              if( option_index !== 0) values += ', '
              values += option.value
            })

          }
        }

        if( field.type == 'REGIONS_MULTIPLE') {
          if( typeof value !== 'object') return;
          if ( !value.length ) return;

          // grouped counties lists
          const denmark = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
          const jutland = [8, 9, 10, 11, 12, 13, 14];
          const zealand = [1, 2, 3, 4, 5];
          const HTarea  = [1, 2, 3];

          let denmark_res = this.groupCounties(value, denmark, 'Hele Danmark')
          let jutland_res = this.groupCounties(value, jutland, 'Jylland')
          let zealand_res = this.groupCounties(value, zealand, 'Sjælland')
          let ht_res      = this.groupCounties(value, HTarea, 'HT-området')

          if( denmark_res ) { values += denmark_res; return false; }
          if( jutland_res ) { values += jutland_res; return false; }
          if( zealand_res ) { values += zealand_res; return false; }
          if( ht_res )      { values += ht_res; return false; }

          // If no groupedCounty match, display values as normal
          value.forEach( (region, region_index) => {
            if( region_index !== 0) values += ', '
            values += region.name
          })

        }

        //if( values == '' ) values = value // catch all.
      })
      return values;
    },

    groupCounties(list, matchList, prettyName) {

        let result = '';

        // Convert value object to ID's
        const listIds = list.map(item => item.id);

        // If list ids has the same as matchList, then it is a match
        if( matchList.every(v => listIds.includes(v)) ) {
          result = prettyName

          // also append those values not in matchList
          list.forEach( (region, region_index) => {
            if( !matchList.includes(region.id) ) {
              result +=  ', ' + region.name
            }
          })
          return result;
        }
    },

    isItemValuesEmpty(field, item_index) {
      var values = this.showItemValues(field, item_index);
      if( values !== '' && values !== null && values !== undefined) return false;
      return true;
    },

    goBack(fallback) {
      if( fallback == undefined ) fallback = '/';
      if( window.history.state.back == null) {
        this.$router.replace({ path: fallback });
      } else {
        this.$router.go(-1);
      }
    },

    translateSalesType(type) {
      if( type == 'RENEGOTIATION') return 'Genforhandling';
      if( type == 'WINBACK') return 'Winback';
      if( type == 'ADDITIONAL_SALES') return 'Mersalg';
      if( type == 'CANVAS') return 'Canvas';
      if( type == 'UNDEFINED') return 'Udefineret';
    }

  }

}

import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`
var opgsyshostname = (process.env.NODE_ENV == 'development') ? 'https://api.development.3byggetilbud.dk' : 'https://api.3byggetilbud.dk';

const config = {}

export default {

    async getById(quoteId) {
        try {
            return await axios.get(
                `${hostname}/quotes/${quoteId}`,
                config
            )
        } catch (error) {
            console.log(error)
        }
    },

    async getByUUID(UUID) { // Public endpoint
      try {
          return await axios.get(
              `${hostname}/public/quotes/${UUID}`,
              config
          )
      } catch (error) {
          console.log(error)
      }
    },

    async getPreviewByUUID(UUID) { // not logging events, for internal use.
      try {
          return await axios.get(
              `${hostname}/public/quotes/${UUID}/preview`,
              config
          )
      } catch (error) {
          console.log(error)
      }
    },



    async save(quote) {
        try {
            return await axios.post(
                `${hostname}/quotes/save`,
                quote,
                config
            )
        } catch (error) {
            console.log(error)
        }
    },

    async create(createTemplateDto) {
        try {
            return await axios.post(
                `${hostname}/quotes/create`,
                createTemplateDto,
                config
            )
        } catch (error) {
            console.log(error)
        }
    },

    async clone(id) {
      try {
        return await axios.post(
          `${hostname}/quotes/${id}/clone`,
          null,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async detachDeal(id) {
      try {
        return await axios.post(
          `${hostname}/quotes/${id}/detach/deal`,
          null,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async getPage(page, size, state) {
        try {
            return await axios.get(
              `${hostname}/quotes/all?page=${page}&size=${size}&state=${state}`,
              config
            )
          } catch (error) {
            console.log(error)
          }
    },

    async getQuotes(page, size, state, status, excludeStates, sort, query, excludeStatus) {
      let url = `${hostname}/quotes/all?page=${page}&size=${size}`
      if( status ) url += `&status=${status}`
      if( state ) url += `&state=${state}`
      if( !state && excludeStates ) url += `&excludeStates=${excludeStates}`
      if( !state && excludeStatus ) url += `&excludeStatuses=${excludeStatus}`
      url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'

      if( query ) url += `&query=${query}`

      try {
        return await axios.get(
          url,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async approveDeal(params) { // Sends Deal to approval at customer
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/action/approve`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async resendDeal(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/email/send`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async resendDealAsText(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/sms/send`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async acceptDealInternal(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/action/accept`,
          null,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async acceptDealExternal(params) {
      try {
        return await axios.post(
          `${hostname}/public/quotes/${params.uuid}/accept`,
          null,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async fetchEvents(params) {
      try {
        return await axios.get(
          `${hostname}/quotes/${params.id}/events`,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async fetchPublicEvents(params) {
      try {
        return await axios.get(
          `${hostname}/public/quotes/${params.id}/events`,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async createInvoice(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/action/create/invoice`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async importSubscription(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/action/import/subscriptions`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async paidDeal(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/action/paid`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },



    async statistics() {
      try {
        return await axios.get(
          `${hostname}/quotes/statistics`,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },

    async fetchAllConsultants() {
      try {
        return await axios.get(
          `${opgsyshostname}/users/all`,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },


    async updateConsultant(params) {
      try {
        return await axios.post(
          `${hostname}/quotes/${params.id}/action/change/owner`,
          params.body,
          config
        )
      } catch (error) {
        console.log(error)
      }
    },



}

import productfieldsApi from '../../api/productfields'


// state
const state = () => ({
    types: [],
})

// getters
const getters = {
    getTypes(state) {
        return state.types
    },
}

// actions
const actions = {
    async getTypes({commit}) {
        var response = await productfieldsApi.getTypes();
        commit('setTypes', response.data.data)
    },

    async getMockup({commit}, params) {
      var response = await productfieldsApi.getMockup(params);
     return response.data.data;
    },

}

// mutators
const mutations = {
    setTypes(state, types) {
        state.types = types
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

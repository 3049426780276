import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {

    async getTypes() {
        try {
            return await axios.get(
                `${hostname}/products/fields/types`,
                config
                )
        } catch (error) {
            console.log(error)
        }
    },

    async getMockup(payload) {
        try {
            return await axios.get(
                `${hostname}/products/fields/mockup?type=${payload.type}&name=${payload.name}`,
                config
                )
        } catch (error) {
            console.log(error)
        }
    }

}

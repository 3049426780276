import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {

  async create(productDto) {
    try {
      return await axios.post(
        `${hostname}/products/create`,
        productDto,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async delete(id) {
    try {
      return await axios.delete(
        `${hostname}/products/${id}/delete`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async update(product) {
    try {
      return await axios.put(
        `${hostname}/products/${product.id}/update`,
        product,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async clone(id) {
    try {
      return await axios.post(
        `${hostname}/products/${id}/clone`,
        null,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async save(product) {
    try {
      return await axios.post(
        `${hostname}/products/save`,
        product,
        config
      )
    } catch (error) {
      console.log(error)

    }
  },

  async getProductById(id) {
    try {
      return await axios.get(
        `${hostname}/products/${id}`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async getProducts(page, size, state, excludeStates, sort, query) {
    let url = `${hostname}/products/all?page=${page}&size=${size}`
    if( state ) url += `&state=${state}`
    if( !state && excludeStates ) url += `&excludeStates=${excludeStates}`
    url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'

    if( query ) url += `&query=${query}`

    try {
      return await axios.get(
        url,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async statistics() {
    try {
      return await axios.get(
        `${hostname}/products/statistics`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

}


<template>

  <div class="default_layout">
    <Navbar />
    <main>

        <div class="corset">
        <slot></slot>
        </div>

    </main>
    <div id="teleport_outside_main"></div>
  </div>

  <!-- <notificationGroup group="default">
    <div
      class="fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end"
    >
      <div class="max-w-sm w-full">
        <notification v-slot="{ notifications }">
          <div
            class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4 bg-green-500"
            v-for="notification in notifications"
            :key="notification.id"
          >

            <div class="-mx-3 py-2 px-4">
              <div class="mx-3">
                <span class="text-white text-sm font-medium">{{
                  notification.title
                }}</span>
                <div v-html="notification.content"></div>
              </div>
            </div>
          </div>
        </notification>
      </div>
    </div>
  </notificationGroup> -->

</template>

<script>
import { ref } from "vue";
import Navbar from "@/components/nav/Navbar";

export default {
  components: {
    Navbar,
  },
  created() {
    this.$notify.fire(
      {
        group: "default",
        title: "Loaded.",
        // content: "TEST!",
      },
      2000
    );
  },

};
</script>

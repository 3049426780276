import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {

  async create(templateDto) {
    try {
      return await axios.post(
        `${hostname}/quotes/templates/create`,
        templateDto,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async delete(id) {
    try {
      return await axios.delete(
        `${hostname}/quotes/templates/${id}/delete`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async update(template) {
    try {
      return await axios.put(
        `${hostname}/quotes/templates/${template.id}/update`,
        template,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async clone(id) {
    try {
      return await axios.post(
        `${hostname}/quotes/templates/${id}/clone`,
        null,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async save(template) {
    try {
      return await axios.post(
        `${hostname}/quotes/templates/save`,
        template,
        config
      )
    } catch (error) {
      console.log(error)

    }
  },

  async getTemplateById(id) {
    try {
      return await axios.get(
        `${hostname}/quotes/templates/${id}`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async getTemplates(page, size, state, excludeStates, sort, query) {
    let url = `${hostname}/quotes/templates/all?page=${page}&size=${size}`
    if( state ) url += `&state=${state}`
    if( !state && excludeStates ) url += `&excludeStates=${excludeStates}`
    url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'

    if( query ) url += `&query=${query}`

    try {
      return await axios.get(
        url,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async statistics() {
    try {
      return await axios.get(
        `${hostname}/quotes/templates/statistics`,
        // config
      )
    } catch (error) {
      console.log(error)
    }
  },

}

import organizationApi from '../../../api/pipedrive/organization'

// state
const state = () => ({
    persons: [],
})

// getters
const getters = {

    getPersons(state) {
        return state.persons.data
    },

}

// actions
const actions = {
    async getPersons({commit}, organization_id) {
        var response = await organizationApi.GetAssociatedPersons(organization_id);
        commit('setPersons', response.data.data)
    },

}

// mutators
const mutations = {

  setPersons(state, persons) {
        state.persons = persons
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>
  <header class="menubar">
    <div class="corset">

        <div class="menubar__flex">

          <router-link to="/quotes" class="menubar__logo">
            <!-- <img class="show_medium" src="@/assets/hi5_logomark.svg" alt="Workflow" />
            <img class="hide_medium" src="@/assets/hi5_logo.svg" alt="Workflow" /> -->
            <Svgicon class="show_medium" icon="hi5_logomark" />
            <Svgicon class="hide_medium" icon="hi5_logo" />

          </router-link>

          <nav class="nav">
            <ul>
              <li v-for="item in authorizedMenuItems" :key="item.title">
                <router-link  v-if="item.to !== undefined" :to="item.to" class="nav__items">{{item.title}}</router-link>
                <a href="#" v-else class="nav__items">{{item.title}}</a>

                <ul v-if="item.children !== undefined" class="nav__children">
                  <li v-for="item in item.children" :key="item.title">
                    <router-link  v-if="item.to !== undefined" :to="item.to" class="nav__items">
                      <Svgicon :icon="item.icon" v-if="item.icon !== undefined" />
                      {{item.title}}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>

          <Search />

          <!-- <div class="bell">
            <Svgicon icon="bell" />
            <span class="bell__orb">
                  <span></span>
                  <span></span>
            </span>
          </div> -->

          <div class="profile">
          <SelectMenu class="profile__menu">
              <template v-slot:trigger>

                    <img class="profile__image" @click="profilemenu_open = !profilemenu_open" :src="profilePictureUrl" alt="" draggable="false">

              </template>
              <template v-slot:items>
                <!-- <a href="/auth/logout">  Log ud</a> -->
                <a href="#" v-if="showLocalScoreLink"  @click.prevent="openLocalScoreModal"><Svgicon icon="star" />  Highscore</a>
                <router-link :to="{name: 'Logout'}" class=""><Svgicon icon="logout" /> Log ud</router-link>
              </template>
          </SelectMenu>

          </div>

          <div class="toggle_darkmode" @click="toggle_darkmode">
            <Svgicon class="dark" icon="moon" />
            <Svgicon class="light" icon="sun" />
          </div>

          <a href="https://doc.clickup.com/20532133/d/h/kjjx5-5145/60b66216396e27d" target="_blank" class="guide">
            <Svgicon class="guide__icon" icon="guide" />
          </a>

           <div class="burger" @click="mobilemenu_open = !mobilemenu_open">
            <span></span>
            <span></span>
            <span></span>
          </div>

        </div> <!--menubar__flex-->

    </div> <!-- corset -->

    <div class="mobilebackdrop" @click="mobilemenu_open = false"></div>
    <nav class="mobilenav" :class="{ 'mobilenav--open' : mobilemenu_open}">
      <ul>
      <li v-for="item in authorizedMenuItems" :key="item.title">
          <router-link  v-if="item.to !== undefined" :to="item.to" class="nav__items">{{item.title}}</router-link>
          <a href="#" v-else class="nav__items">{{item.title}}</a>

          <ul v-if="item.children !== undefined" class="nav__children">
            <li v-for="item in item.children" :key="item.title">
              <router-link  v-if="item.to !== undefined" :to="item.to" class="nav__items">
                <Svgicon :icon="item.icon" v-if="item.icon !== undefined" />
                {{item.title}}
              </router-link>
            </li>
          </ul>
      </li>
      </ul>
    </nav>



  </header>
  <Modal ref="localscoremodal" class="localscoremodal" maxwidth="550px">
      <h2>Highscore</h2>
      <div v-if="getLocalScore">
        <ul class="localscore">
          <li v-for="(item, index) in getLocalScore" :key="item.score">
            <p class="localscore__score"><span>{{index + 1}}.</span> {{item.score}}</p>
            <p class="localscore__price">Tilbudspris: {{item.price}}</p>
            <p class="localscore__clicks">Klik: {{item.clicks}}</p>
            <p class="localscore__date">Dato: {{item.date}}</p>
          </li>
        </ul>

      </div>

  </Modal>

</template>

<script>

import Search from "./search/Search";
import SelectMenu from "@/components/SelectMenu";
import Modal from "@/components/dialog/Modal";

const menuItems = [
  { title: 'Tilbud',  to: { name: 'Quotes',            } },
  { title: 'Håndværkere',   to: { name: 'Contractors'   } },
  { title: 'Analyse',   to: '/analyse' },

  {
    title: 'Admin',
    children: [
      { title: 'Regnskab',   to: '/accounting', icon: 'cityhall', auth: { roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'] } },
      { title: 'Rykkerliste',   to: '/reminders', icon: 'flag', auth: { roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'] } },
      { title: 'Indsigt',   to: '/report', icon: 'settings',
        auth: {
          roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'],
          attributes: ['isManager']
        }
      },
      { title: 'Produkter',    to: '/products', icon: 'tag',  auth: { roles: ['ROLE_ADMIN'] } },
      { title: 'Skabeloner',   to: '/templates', icon: 'doc',  auth: { roles: ['ROLE_ADMIN'] } },
    ],
    auth: {
      roles: ['ROLE_MODERATOR','ROLE_ADMIN'],
      attributes: ['isManager']
    }
  },
];

export default {
  components: {
    Search,
    SelectMenu,
    Modal,
  },


  data() {
      return {
          mobilemenu_open: false,
      }
  },

  computed: {
    authorizedMenuItems() {
      // return this.menuItems
      let authorizedMenuItems = this.r(menuItems)
      return authorizedMenuItems
    },

    getLocalScore() {
      var localScore = localStorage.getItem('localScore');
      if (localScore == null) return false;
      var parsedScore = JSON.parse(localScore);
      // Order localScore by score
      return parsedScore.sort((a, b) => a.score - b.score);
    },


    showLocalScoreLink() {
      if (localStorage.getItem('localScore') !== null) return true
      return false;
    },

    profilePictureUrl() {
      let userDetails = this.$auth.me()
      return userDetails.profileImageUrl
    }

  },

  methods: {
    // Recursive function to filter menuItems based on auth
    r(items) {

      if (!items) return [];

      let result = []
      items.forEach(item => {

          if ((item.auth && item.auth?.roles) || (item.auth && item.auth?.attributes)) {
            if (this.isAuthorized(item)) {
              item.children = this.r(item.children)
              result.push(item)
            }
          } else {
            item.children = this.r(item.children)
            result.push(item)
          }

      })
      return result
    },

    showNotifications() {
      console.log('open')
    },

    isAuthorized(menuItem) {

      let isAuthorized = true;

      if (menuItem?.auth?.roles) {
        isAuthorized = this.$auth.isAuthorized(menuItem.auth.roles);
      }

      // If not authorized by role, check if authorized by attribute
      if (isAuthorized == false || menuItem?.auth?.roles == undefined ) {
        if (menuItem?.auth?.attributes) {
          isAuthorized = this.$auth.isAuthorizedByAttributes(menuItem.auth.attributes);
        }
      }

      return isAuthorized;
    },

    toggle_darkmode() {
      var theme = document.getElementsByTagName( 'html' )[0].getAttribute('theme')
      if( theme == 'dark' ) {
        localStorage.removeItem('colortheme')
        document.getElementsByTagName( 'html' )[0].removeAttribute('theme')
      } else {
        localStorage.setItem('colortheme', 'dark')
        document.getElementsByTagName( 'html' )[0].setAttribute('theme', 'dark')
      }

    },


    openLocalScoreModal() {
      this.$refs.localscoremodal.open = true
    },



  },

  async created() {
    this.$watch('mobilemenu_open', (currentValue, oldVal) => {
      if( currentValue ) {
          document.getElementsByTagName( 'html' )[0].classList.add('mobile_menu_open')
        } else {
          document.getElementsByTagName( 'html' )[0].classList.remove('mobile_menu_open')
        }
    })

  },

};
</script>

<style lang="scss">
.menubar {
  background: color(white);
  box-shadow: var(--shadow3);
  padding: 6px 0;
  position: relative;
  z-index: 1;

  &__flex {
    display: flex;
    align-items: center;
    z-index: 999;
    position: relative;
  }

  &__logo {
      width: 170px;
      border-right: 1px solid color(color10);
      padding-right: 20px;

      @include medium {
        width: 50px;
        border: none;
      }
  }

}

.nav {

  ul {
    display: inline-flex;
    margin-left: 15px;
  }

  @include medium {
    display: none;
  }


  &__items {
    display: block;
    padding: 7px 15px 5px 15px;
    margin: 10px;
    transition: 0.15s ease;
    border-radius: $radius;
    color: color(color1);
    font-size: 0.9rem;

    &:hover {
      background-color: color(color6);
      + .nav__children {
        opacity: 1;
        visibility: visible;
      }
    }

    &.router-link-active {
      background-color: color(color1);
      color: color(white);
    }

  }

  li {
    position: relative;
    list-style: none;
  }

  .svgicon {
    width: 20px;
    display: block;
    margin-right: 10px;
  }

  &__children {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 55px;
    background: color(white);
    border-radius: $radius;
    box-shadow: var(--shadow3);
    display: flex;
    flex-direction: column;
    width: 170px;
    left: -30px;
    margin: 0;
    transition: 0.2s $easeInOutCirc;

    .nav__items {
        margin: 0 10px 10px 10px;
        display: flex;
        align-items: center;
    }

    &:hover {
        opacity: 1;
        visibility: visible;
    }
  }

}


.bell {
  padding-top: 2px;
  width: 23px;
  margin: 0 30px;
  position: relative;
  cursor: pointer;
  color: color(color1);
  &:hover {
    svg {
      color: color(color1);
    }
  }


  &__orb {
    position: absolute;
    height: 10px;
    width: 10px;
    top: 3px;
    right: 0;
    span:first-of-type {
      background: $error;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      border: 2px solid color(white);
      display: block;
    }
    span:last-of-type {
      border: 1px solid $error;
      width: 100%;
      height: 100%;
      transform: scale(0.8);
      border-radius: 100px;
      position: absolute;
      top: 0;
      animation: orb_notice 3s ease;
      animation-iteration-count: infinite;
    }

    @keyframes orb_notice {
      0% { transform: scale(0.8); opacity: 1; }
      30%  { transform: scale(2); opacity: 0; }
      100%  { transform: scale(2); opacity: 0; }
    }
  }
}

.profile {
  cursor: pointer;
  perspective: 1200px;
  margin-left: 1rem;

  &__image {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 200px;
    overflow: hidden;
    user-select: none;
    border: 1px solid color(color2);
  }



}

.profile__menu {
  .selectmenu__dropdown {
        top: 50px;
  }
}

.burger {
  	display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    height: 100%;
    cursor: pointer;
    @include gutter('margin-left');
    &:hover,
    &:active {
      span:nth-child(3) {
        width: 100%;
      }
    }


    span {
      width: 100%;
      height: 3px;
      background: color(color1);
      margin-bottom: 5px;
      display: block;
      transition: 0.2s $easeInOutSine;
      transform-origin: left;
      border-radius: 10px;
    }

    span:nth-child(2) {
      width: 60%;
    }

    span:nth-child(3) {
      width: 80%;
      margin-bottom: 0;
    }

    @include medium_up {
      display: none;
    }
}

.mobilenav {
  position: fixed;
  background: color(white);
  top: 70px;
  right: 10px;
  padding: 15px;
  border-radius: $radius * 2;
  width: 320px;
  transition: 0.45s $easeInOutExpo;
  height: 100vh;
  z-index: 99;
  box-shadow: var(--shadow4);
  height: calc( 100% - 110px );
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transform-origin: top right;
  pointer-events: none;

  li a {
    display: block;
    padding: 10px 15px;
    border-radius: $radius;
    margin-top: 7px;
    background: color(color6);
  }

  &--open {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    pointer-events: all;
  }

  .nav__children {
    position: relative;
    opacity: 1;
    visibility: visible;
    top: 0;
    left: 0;
    width: 90%;
  }

}

.mobilebackdrop {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background: color("color1", $alpha: -0.65);
  z-index: 98;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.2s;
}


html.mobile_menu_open {
  overflow: hidden;

    .mobilebackdrop {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    .burger {
      span:nth-child(1) {
        transform: rotate(45deg) translate(-3px, 0px);
      }
      span:nth-child(2) {
        width:0;
      }
      span:nth-child(3) {
        transform: rotate(-45deg) translate(-3px, 1px);
      }
    }
}



.toggle_darkmode {
  position: relative;
  overflow: hidden;
  width: 35px;
  height: 35px;
  margin: 0 20px;
  position: relative;
  cursor: pointer;
  color: color(color1);
  padding: 5px;
  border-radius: 5px;
  box-shadow: var(--shadow2);

  .svgicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    transition: 0.4s;
  }

  &:hover {
    svg {
      color: color(color1);
    }
  }

  .light {
    transform: translate(-50%, 150%);
  }
}

html[theme=dark] {
  .toggle_darkmode {
    .light {
      transform: translate(-50%, -50%);
    }
    .dark {
      transform: translate(-50%, 150%);
    }
  }
}


.guide {
  position: relative;
  overflow: hidden;
  width: 35px;
  height: 35px;
  margin: 0;
  position: relative;
  cursor: pointer;
  color: color(color1);
  padding: 5px;
  border-radius: 5px;
  box-shadow: var(--shadow2);

  .svgicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    transition: 0.4s;
  }

  &:hover {
    svg {
      color: color(color1);
    }
  }
}


.localscore {
  margin: 0;
  padding: 0;
  li {
    padding: 1rem 0rem;
    border-bottom: 2px dashed color('color2');
    p {
      margin: 0;
    }
  }
  &__score {
    font-size: 3rem;
    font-weight: bold;
    color: gold;
    line-height: 1;
    span {
      color: color(color1);
    }
  }

  &__date,
  &__clicks,
  &__price {
        display: inline-block;
    opacity: 0.4;
    padding-right: 25px;
    font-size: 0.8rem;
  }
}

</style>

import { createStore } from "vuex"

import Global from './modules/global'
import Products from './modules/products'
import ProductFields from './modules/productfields'
import ProductCategories from './modules/productCategories'
import Quotes from './modules/quotes'
import Templates from './modules/templates'
import Deals from './modules/pipedrive/deals'
import Persons from './modules/pipedrive/persons'
import Organizations from './modules/pipedrive/organizations'
import Contractors from './modules/contractors'
import Accounting from './modules/accounting'


const store = createStore({
   state:{
      name: "Vue"
   },
   modules: {
       global: Global,
       products: Products,
       productfields: ProductFields,
       deals: Deals,
       persons: Persons,
       organizations: Organizations,
       quotes: Quotes,
       templates: Templates,
       contractors: Contractors,
       productcategories: ProductCategories,
       accounting: Accounting,
   }
})

export default store

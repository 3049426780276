import {StoreLocalStorage} from './store/store-local';
import {StoreSessionStorage} from './store/store-session';

export class PipedriveStoreManager {

    stores = []

    constructor(Vue, options) {
        this.Vue = Vue
        this.options = options
        this.setStores();
    }

    allStores() {
        return this.stores
    }

    setStores() {
        this.stores = Object.assign([], this.options.tokenStore)
        .map(store => {
            switch (store) {
                case 'vuex':
                    // return this.Vue.prototype.$store
                case 'localStorage':
                    return new StoreLocalStorage(this.Vue, this.options)
                case 'sessionStorage':
                    return new StoreSessionStorage(this.Vue, this.options)
                default:
                    return new StoreLocalStorage(this.Vue, this.options);
            }
        })
    }

    getUser() {
        const user = this.stores
            .map((store) => store.getUser())
            .filter(Boolean)[0];
        return user
    }

    setUser(user) {
        this.stores
            .forEach((store) => {
                store.setUser(user);
            });
    }

    resetAll() {
        this.setUser(null);
    }

    isLinked() {
        return this.getUser() != null
    }
}
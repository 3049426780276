import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

export default {

    async getPersons(query) {
        try {
            let uri = '/pipedrive/persons/all';
            if( query !== undefined ) {
              uri += '?q='+query;
            }
            return await axios.get(`${hostname}${uri}`)
        } catch (error) {
            console.log(error)
        }
    },

}

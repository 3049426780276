import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

export default {

    async getStatuses() {
        try {
            return await axios.get(`${hostname}/pipedrive/deals/statuses`)
        } catch (error) {
            console.log(error)
        }
    },

    async getPage(page, size) {
        try {
            return await axios.get(`${hostname}/pipedrive/deals/all?page=${page}&size=${size}`)
        } catch (error) {
            console.log(error)
        }
    },

    async search(query, page, size) {
        try {
            return await axios.get(`${hostname}/pipedrive/deals/search?query=${query}&page=${page}&size=${size}`)
        } catch (error) {
            console.log(error)
        }
    },

    async getDeals(page, size, sort, query) {
      let url = `${hostname}/pipedrive/deals/all?page=${page}&size=${size}`
      url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'
      if( query ) url += `&query=${query}`

      try {
        return await axios.get(
          url
        )
      } catch (error) {
        console.log(error)
      }
    },

}

import templateApi from '../../api/templates'


// state
const state = () => ({
  template: null,
  templateError: null,
  page: null,
  countAll: 0,
  countArchived: 0,
  statistics: null,
})

// getters
const getters = {
  getTemplate(state) {
    return state.template
  },
  getTemplateFieldByIndex: (state) => (idx) => {
    return state.template.fields[idx]
  },
  getPage(state) {
    return state.page
  },
  getStatistics( state ) {
    return state.statistics
  }
}
// actions
const actions = {
  async save({ commit }, params) {
    var response = await templateApi.save(params);
    commit('setTemplate', response.data.data)
    return response.data.data
  },

  async clone({ commit }, params) {
    var response = await templateApi.clone(params.id);
    commit('setTemplate', response.data.data)
    return params.id
  },

  async delete({ commit }, id) {
    var response = await templateApi.delete(id);
    commit('setTemplate', response.data.data)
    commit('removeTemplateFromPage', id)
  },

  async search({ commit }, query) {
    var response = await templateApi.search(query);
    return reponse.data.data
  },

  async updateState({ commit }, params) {  // {id: 3, state: "ARCHIVED"}
    var response = await templateApi.update(params);
    commit('setTemplate', response.data.data)
    commit('removeTemplateInPage', response.data.data)

    return response;
  },


  async getTemplateById({ commit }, params) {
    commit('setTemplate', null)
    try {
      var response = await templateApi.getTemplateById(params.id);
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setTemplate', response.data.data)
        return response.data.data
      } else {
        commit('setTemplate', null)
        commit('setTemplateError', response.data.data)
      }

    } catch (err) {
      console.log('getTemplateById', err)
      commit('setTemplate', null)
    }

  },


  async getTemplatePage({ commit, dispatch }, params) {
    let templates  = await dispatch('getTemplates', params)
    commit('setPage', templates)
  },

  async getTemplates({ commit }, params) {
    if (typeof params == 'undefined') params = {}
    var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 10
    var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0
    var state = (typeof params.state != 'undefined' && params.state != null) ? params.state : null
    var excludeStates = (typeof params.excludeStates != 'undefined' && params.excludeStates != null) ? params.excludeStates : null
    var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
    var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

    if( page > 0) page = page - 1 // Make zero based,

    var response = await templateApi.getTemplates(page, size, state, excludeStates, sort, query)

    return response.data.data;
  },

  async statistics({ commit }) {
    var response = await templateApi.statistics();
    if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
      commit('setStatistics', response.data.data)
      return response.data.data
    }
  },
}
// mutations
const mutations = {
  setTemplate(state, template) {
    if( template !== null) {
      template.subheads = template.subheads.sort( (a, b) => (a.sortPosition > b.sortPosition) ? 1 : -1)
   }
    state.template = template
  },
  removeTemplateInPage(state, template) {
    if( state.page == null ) return false;

      const templates = state.page.content
      let index = templates.map(item => item.id).indexOf(template.id);
      if(index >= 0) { templates.splice(index, 1) }
  },

  addTemplateField(state, { field }) {
    if (state.template.fields == null) state.template.fields = []
    state.template.fields.push(field)
  },

  setTemplateError(state, error) {
    state.templateError = error
  },

  setPage(state, page) {
    state.page = page
  },

  removeTemplateFromPage(state, templateId) {
    const templates = state.page.content
    let removeIndex = templates.map(item => item.id).indexOf(templateId);
    (removeIndex >= 0) && templates.splice(removeIndex, 1);
  },

  setStatistics( state, params ) {
    state.statistics = params
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>

  <div class="selectmenu">

    <div class="selectmenu__trigger"
      tabindex="1"
      @click="open_menu = !open_menu"

      @blur="handleFocusOut"
      >
      <slot name="trigger">Click me.</slot>
    </div>

    <transition name="selectmenu__anim" >
    <div class="selectmenu__dropdown" v-show="open_menu" >
      <slot name="items">
        <a href="#">Please fill out items slot.</a>
      </slot>
    </div>
    </transition>

  </div>
</template>

<script>


export default {
  name: 'SelectMenu',
  data() {
    return {
      open_menu: false,
    };
  },

  methods: {

    handleFocus() {
      this.open_menu = true;
    },

    handleFocusOut() {
      this.open_menu = false;
    },

  },


}
</script>


<style lang="scss">

  .selectmenu {

    position: relative;

    &__trigger {
      position: relative;
      cursor: pointer;
    }

    &__dropdown {
      position: absolute;
      background: color(white);
      padding: 10px;
      border-radius: $radius;
      border: 1px solid rgba(black, 0.1);
      box-shadow: var(--shadow3);
      right: 0px;
      min-width: 180px;
      z-index: 99;
      display: flex;
      flex-direction: column;

      > * {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        padding: 8px;
        border-radius: $radius;
        transition: 0.07s;
        text-decoration: none;
        font-size: 0.9rem;
        color: color(fontcolor);
        &:hover {
          background: color(color6);
        }

      }
      .svgicon {
          width: 20px;
          color: color(fontcolor);
          margin-right: 5px;
        }
    }

  }



  .selectmenu__anim-leave-active,
  .selectmenu__anim-enter-active {
    transition: 0.07s $easeInOutSine;
  }

  .selectmenu__anim-enter-to,
  .selectmenu__anim-leave-from {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }

  .selectmenu__anim-leave-to,
  .selectmenu__anim-enter-from  {
    transform-origin: top right;
    opacity: 0;
    transform: translateY(5px) scale(0.9);
  }


</style>


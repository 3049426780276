<template>

  <input
    ref="inputRef"
    type="number"
    :value="formatted_val"
    @input="update($event)"
  >
</template>

<script>
//import { ref } from 'vue'
export default {
  name: 'CurrencyInput',
  props: {
      modelValue: {},
      minPrice: 0,
      defaultValue: null,
  },

  emits: ['update:modelValue'],

  methods: {

    // Returned value. Also Makes v-model work. e.g => 1000
    update($event) {

      // Captire cursor position
      const el = $event.target;
      const sel = el.selectionStart;

      var val     = el.value
      let num     = val.toString().replace(/\./g, '') // renomve dots
      num         = parseFloat(num)

      // if( val == '.000' ) num = 000
      // if( val == '0.000' ) num = 0000

      if( num == null || Number.isNaN(num) ) num = 0;
      this.$emit('update:modelValue', num)

      // Restore cursor position
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel);
      })
    },

  },

  computed: {

    // Mask value. What is being displayed in field. e.g => 1.000
    formatted_val() {
      let number  = parseFloat(this.modelValue)
      if( number == null || Number.isNaN(number) ) return 0;

     let num     = number.toString().replace(/\./g, '') // renomve dots
      num         = parseFloat(num)

      var formatter = new Intl.NumberFormat("da-DK");
      var val = formatter.format(num);

      return val
    }
  },

  mounted() {
    if(this.defaultValue && this.modelValue == null ) {
      let input = this.$refs.inputRef
      input.value = this.defaultValue
      input.dispatchEvent(new Event('input')) // Trigger update so v-model's value gets notified of change.
    }

  },

  // setup (props) {
  //   const inputRef = ref(inputRef)

  //   return { inputRef }
  // }
}
</script>

import { AuthHttp } from "./auth-http"

export class AuthVueRouterGuard {
    constructor(Vue, storeManager, options) {
        this.Vue = Vue
        this.storeManager = storeManager
        this.options = options
        this.router = options.router
        this.http = new AuthHttp(Vue, options)

        if (!this.router) {
            throw Error('[vue-auth-plugin] vue-router is a required dependency')
        }

        this.handleRouting()
    }

    async handleRouting() {
        this.router.beforeResolve((to, from, next) => {

            if (typeof to.meta?.auth != 'undefined') {



                if (this.storeManager.getToken() == null && (!to.path.includes('/auth/login') || !to.path.includes('/auth/authenticate'))) {
                    console.log('[vue-auth-plugin] Token is missing, redirecting to login')
                    this.storeManager.resetAll()
                    this.options.router.push(this.options.authRedirect)
                }


                let isAuthorized = true;
                // Check if user is authorized by role

                if (to.meta && to.meta.auth && to.meta.auth.roles) {
                    isAuthorized = this.storeManager.authorized(to.meta.auth.roles)
                }

                // If not authorized by role, check if authorized by attribute
                if (isAuthorized == false || to.meta?.auth?.roles == undefined ) {
                  if (to.meta && to.meta.auth && to.meta.auth.attributes) {
                    isAuthorized = this.storeManager.authorizedByAttribute(to.meta.auth.attributes)
                  }
                }


                if( isAuthorized == false && (!to.path.includes('/auth/login') || !to.path.includes('/auth/authenticate') ) ) {
                  this.storeManager.resetAll()
                  next(this.options.authRedirect)
                }

                next()
            } else {
                next()
            }
        })
    }

}

import productCategoryApi from '../../api/productCategories'


// state
const state = () => ({
  category: null,
  categoryError: null,
  page: null,
})

// getters
const getters = {
  getCategory(state) {
    return state.category
  },

  getPage(state) {
    return state.page
  },

  // Push children into parent
  getSortedCategories(state) {
    if( state?.page?.content == undefined) return [];

    var categories  = JSON.parse( JSON.stringify(state.page.content));
    var index       = categories.length - 1;

    while (index >= 0) { // Reverse loop
      var cat = categories[index];

      if( cat.parent !== null ) {
        let parent_id       = categories.findIndex((p_cat) => p_cat.id == cat.parent.id)
        let parent          = categories[parent_id]
        parent.children     = parent.children || []
        parent.children.push(cat) // Add to parent
        categories.splice(index, 1) // Remove from original array
      }

      index -= 1;
    }

    return categories;
  },

}

// actions
const actions = {
  async create({ commit }, params) {
    var response = await productCategoryApi.create(params);
    commit('setCategory', response.data.data)
    return response.data
  },

  async delete({ commit }, id) {
    var response = await productCategoryApi.delete(id);
    if( response.data.statusCode == 200) {
      commit('deleteItem', id);
    }
    return response.data
  },

  async update({ commit }, params) {
    var response = await productCategoryApi.update(params);
    if( response.data.statusCode >= 200 && response.data.statusCode < 300) {
    commit('setCategory', response.data.data)
    }
    return response.data;
  },

  async getById({ commit }, params) {
    commit('setCategory', null)
    try {
      var response = await productCategoryApi.getById(params.id);
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setCategory', response.data.data)
        return response.data.data
      } else {
        commit('setCategory', null)
        commit('setCategoryError', response.data.data)
      }

    } catch (err) {
      console.log('getById', err)
      commit('setCategory', null)
    }

  },

  async getPage({ commit, dispatch }) {
    let categories  = await productCategoryApi.getAll();
    commit('setPage', categories.data.data)
  },

}


// mutators
const mutations = {
  setCategory(state, category) {
    state.category = category
    if( state?.page?.content !== undefined) {
      let index = state.page.content.findIndex((cat) => cat.id == category.id)
      console.log(index)
      if( index !== -1) {
        state.page.content[index] = category
      } else {
        state.page.content.push(category)
      }


    }
  },

  setPage(state, page) {
    state.page = page
  },

  setCategoryError(state, error) {
    state.categoryError = error
  },

  deleteItem(state, id) {
    let index = state.page.content.findIndex((cat) => cat.id == id)
    if( index !== -1) {
      state.page.content.splice(index, 1)
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

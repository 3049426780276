import { AuthStoreManager } from "./auth-store-manager"
import { AuthVueRouterGuard } from "./auth-vue-router-guard"
import { AuthAxiosInterceptor } from './auth-axios-interceptor'
import { AuthHttp } from './auth-http'

export const DEFAULT_OPTIONS = {
    authMeta: 'auth',
    rolesVar: 'authorities',
    tokenDefaultName: 'default_auth_token',
    userDefaultName: 'default_auth_user',
    tokenStore: [
        'vuex',
        'localStorage',
        'sessionStorage'
    ],
    headerTokenReplace: '{auth_token}',
    tokenType: 'Bearer',
    vuexStoreSpace: 'vue-auth',

    authRedirect: '/auth/login',
    callback: null,
    loginAuth: null,

    requestTokenEndpoint: {
        url: '/auth/request',
        method: 'POST',
        redirect: '/',
        fetchUser: false,
    },
    loginEndpoint: {
        url: '/oauth/token',
        method: 'POST',
        redirect: '/',
        fetchUser: false,
    },
    checkTokenEndpoint: {
        url: '/oauth/check_token',
        method: 'GET',
        redirect: '/',
        fetchUser: false,
    },
    fetchEndpoint: {
        url: '/auth/me',
        method: 'GET',
        interval: 30,
        enabled: false,
    },
    refreshEndpoint: {
        url: '/auth/refresh',
        method: 'GET',
        interval: 30,
        enabled: false,
    },
}

export class Auth {
    options = null

    axiosInterceptor = null
    storeManager = null
    router = null
    http = null

    constructor(Vue, options = {}) {
        this.options = { ...DEFAULT_OPTIONS, ...options }
        this.storeManager = new AuthStoreManager(Vue, this.options)
        this.router = new AuthVueRouterGuard(Vue, this.storeManager, this.options)
        this.axiosInterceptor = new AuthAxiosInterceptor(Vue, this.storeManager, this.options)
        this.http = new AuthHttp(Vue, this.options)

        if (this.options.callback) {
            this.options.callback()
        }

        this.refresh()
    }

    async refresh() {

      const to = window.location.pathname;
        if (typeof to != 'undefined' && (to.includes('/auth/login') || to.includes('/auth/authenticate'))) {
            this.storeManager.resetAll()
            // res.details = this.http.me()
            // this.storeManager.setUser(res)
        }
        else if (this.storeManager.getToken() != null) {
            let r = await this.http.checkToken(this.storeManager.getToken()).then(res => {
                if (res.error) {
                    this.storeManager.resetAll()
                    this.options.router.push(this.options.authRedirect)
                }
            })
        }
    }

    async authenticate(jwt) {

        // Request auth token with temporary token
        let response = await this.http.requestAuthToken(jwt) // response.data.token

        // Set new token
        this.storeManager.resetAll()
        this.storeManager.setToken(response.data.token)
        let user = await this.http.checkToken(response.data.token)
        // Fetch user
        user.details = await this.http.me()
        this.storeManager.setUser(user)
        this.options.router.push("/");
    }

    async login(username, password) {
        let response = await this.http.login(username, password)
        this.storeManager.resetAll()
        this.storeManager.setToken(response.access_token)

        let user = await this.http.checkToken(response.access_token)
        user.details = await this.http.me()
        this.storeManager.setUser(user)
        this.options.router.push("/");
    }

    logout() {
        this.reset()
    }

    reset() {
        this.storeManager.resetAll()
    }

    isAuthenticated() {
        return this.storeManager.getToken() != null && this.storeManager.getUser() != null;
    }

    isAuthorized(roles) {
        return this.storeManager.authorized(roles)
    }

    isAuthorizedByAttributes(attribute) {
      return this.storeManager.authorizedByAttribute(attribute)
    }

    me() {
        let user = this.storeManager.getUser()
        let details = null

        if (user == null) {
            details = this.http.me()
        } else {
            details = user.details
        }

        return details
    }
}

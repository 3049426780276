<template>
  <span
    class="statusbadge"
    :class="[
      size == 'large' || size == 'lg' ? `large` : ``,
      selectableClass,
      bgColorClass,
      activeClass,
    ]"
    show.bind="size == 'small'"
    @click="select"
  >
    <svg
      class="statusbadge__dot"
      fill="currentColor"
      viewBox="0 0 8 8"
      v-show="showDot"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <slot></slot>
    {{autoFillSlotIfEmpty}}
  </span>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    size: {
      type: String,
      default: "small",
    },
    active: {
      type: Boolean,
      default: false
    },
    showDot: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: 'draft'
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    select() {
      if (this.selectable) {
        this.selected = !this.selected

        let state = this.status
        if( this.isActive ) state = ''; // On click if active, means its about to be disabled => therefore emit empty state.
        this.$emit('selected', state)
      }
    }
  },
  computed: {

    bgColorClass: function() {
      return this.status != null ? `status-${this.status.toLowerCase()}` : 'status-undefined';
    },
    activeClass: function() {
      return this.isActive ? `statusbadge--active` : 'statusbadge--inactive'
    },
    selectableClass: function() {
      return this.selectable ? `statusbadge--selectable` : ``
    },
    isActive: function() {
      return this.active // || this.selected
    },

    autoFillSlotIfEmpty() {
      if(!this.$slots.default) {
          let status = this.status != null ? this.status.toLowerCase() : 'undefined';
          if( status == 'draft') return "Kladde"
          if( status == 'undefined') return "Kladde"
          if( status == 'active') return "Aktiv"
          if( status == 'inactive') return "Inaktiv"
          if( status == 'sent') return "Sendt"
          if( status == 'accepted') return "Accepteret"
          if( status == 'invoiced') return "Faktureret"
          if( status == 'paid') return "Betalt"
          if( status == 'cancelled') return "Annulleret"
          if( status == 'partially_cancelled') return "Delvist annulleret"
          if( status == 'collection_external') return "Ekstern inkasso"
          if( status == 'collection_external_cancelled') return "Annulleret ekstern inkasso"
          if( status == 'collection_external_partially_cancelled') return "Delvist annulleret ekstern inkasso"
          if( status == 'collection_external_paid') return "Betalt ekstern inkasso"
          if( status == 'collection_external_installment_plan') return "Afdragsordning ekstern inkasso"
          if( status == 'archived') return "Slettet"
          if( status == 'rejected') return "Afvist"
          if( status == 'installment_plan') return "Ratebetaling"
          if( status == 'collection_internal_installment_plan') return "Afdragsordning"
          if( status == 'collection_internal') return "Intern inkasso"
          if( status == 'invoice_pending') return "Afvent fakturering"
          if( status == 'additional_sales') return "Mersalg"
          if( status == 'canvas') return "Canvas"
          if( status == 'renegotiation') return "Genforhandling"
          if( status == 'winback') return "Winback"
      }
    },


  },





  setup() {
    const selected = ref(false)

    return {
      selected
    }
  },

};
</script>

<style scoped lang="scss">

.statusbadge {
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  user-select: none;
  padding: 4px 9px 2px 9px;
  font-size: 0.75rem;
  line-height: 1;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border: 1px solid;
  transition: 0.05s;

  &__dot {
    width: 6px;
    height: 6px;
    margin-right: 5px;
    bottom: 1px;
    position: relative;
  }

  &--inactive {
    border-color: color(color11);
    background: transparent;
    color: color(fontcolor);
    user-select: none;
  }

}


// Colors moved to variables.scss



</style>

import Notification from "./view/Notification.vue"
import NotificationGroup from "./view/NotificationGroup.vue"
import { methods } from './notifications.js'

let installed = false

export default {
  install(app) {
    if (installed) return
    app.component('notification', Notification)
    app.component('notificationGroup', NotificationGroup)
    // app.config.globalProperties.$notify = (data, timeout) => methods.notify(data, timeout)
    app.config.globalProperties.$notify = methods
    installed = true
  }
}
import accountingApi from '../../api/accounting';

// state
const state = {
    page: null,
    statistics: null,
}

// getters
const getters = {

    getPage(state) {
      return state.page
    },

    getStatistics(state) {
      return state.statistics
    },

}

// actions
const actions = {

    async search({ commit }, query) {
      var response = await accountingApi.search(query);
      return response.data.data
    },
    async getReminderPage({ commit, dispatch }, params) {
      let reminders  = await dispatch('getReminders', params)
      commit('setPage', reminders)
    },

    async getReportPage({ commit, dispatch }, params) {
      let report  = await dispatch('getReport', params)
      commit('setPage', report)
    },

    async getReminders({ commit }, params) {
      if (typeof params == 'undefined') params = {}
      var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 15
      var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0

      var from = (typeof params.from != 'undefined' && params.from != null) ? params.from : Date.now() - ( (24*60*60*1000 ) * 1 )
      var to = (typeof params.to != 'undefined' && params.to != null) ? params.to : Date.now()

      var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
      var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

      if( page > 0) page = page - 1 // Make zero based,

      var response = await accountingApi.getReminders(page, size, from, to, sort, query)
      if( response != undefined ) {
        return response.data.data;
      }
      return null;
    },

    async getReport({ commit }, params) {
      if (typeof params == 'undefined') params = {}
      var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 15
      var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0

      var dateRangeFrom = (typeof params.dateRangeFrom != 'undefined' && params.dateRangeFrom != null) ? params.dateRangeFrom : Date.now() - ( (24*60*60*1000 ) * 30 )
      var dateRangeTo = (typeof params.dateRangeTo != 'undefined' && params.dateRangeTo != null) ? params.dateRangeTo : Date.now()
      var dateRangeState = (typeof params.dateRangeState != 'undefined' && params.dateRangeState != null) ? params.dateRangeState : 'ACTIVE'
      var dateRangeStatus = (typeof params.dateRangeStatus != 'undefined' && params.dateRangeStatus != null) ? params.dateRangeStatus : ''
      var filterCurrentStatuses = (typeof params.filterCurrentStatuses != 'undefined' && params.filterCurrentStatuses != null) ? params.filterCurrentStatuses : null
      var filterConsultantIds = (typeof params.filterConsultantIds != 'undefined' && params.filterConsultantIds != null) ? params.filterConsultantIds : null
      var filterQuoteTypes = (typeof params.filterQuoteTypes != 'undefined' && params.filterQuoteTypes != null) ? params.filterQuoteTypes : null


      var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
      var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

      if( page > 0) page = page - 1 // Make zero based,

      var response = await accountingApi.getReport(dateRangeFrom, dateRangeTo, dateRangeState, dateRangeStatus, filterCurrentStatuses, filterConsultantIds, filterQuoteTypes, page, size, sort, query)
      if( response != undefined ) {
        return response.data.data;
      }
      return null;
    },

    async getStatistics({ commit }, params) {
      if (typeof params == 'undefined') params = {}
      var from = (typeof params.from != 'undefined' && params.from != null) ? params.from : Date.now() - ( (24*60*60*1000 ) * 30 )
      var to = (typeof params.to != 'undefined' && params.to != null) ? params.to : Date.now()
      var status = (typeof params.status != 'undefined' && params.status != null) ? params.status : 'SENT'

      var filterCurrentStatuses = (typeof params.filterCurrentStatuses != 'undefined' && params.filterCurrentStatuses != null) ? params.filterCurrentStatuses : null
      var filterConsultantIds = (typeof params.filterConsultantIds != 'undefined' && params.filterConsultantIds != null) ? params.filterConsultantIds : null
      var filterQuoteTypes = (typeof params.filterQuoteTypes != 'undefined' && params.filterQuoteTypes != null) ? params.filterQuoteTypes : null

      var response = await accountingApi.getStatistics(from, to, status, filterCurrentStatuses, filterConsultantIds, filterQuoteTypes)
      if( response != undefined ) {
        commit('setStatistics', response.data.data)
        return response.data;
      }
      commit('setStatistics', null)
      return null;
    },
}

// mutations
const mutations = {

    setPage(state, page) {
      state.page = page
    },

    setStatistics(state, statistics) {
      state.statistics = statistics
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


import axios from 'axios'

export class AuthAxiosInterceptor {
    constructor(Vue, storeManager, options) {
        this.Vue = Vue
        this.storeManager = storeManager
        this.options = options

        this.handleRequests()
        // this.handleResponses()
    }

    handleRequests() {
        axios.interceptors.request.use(request => {

            let token = this.storeManager.getToken()
            //console.log(request, token, localStorage.getItem(this.options.tokenDefaultName))
            //if (typeof token != 'undefined' && token != null) request.headers.common.Authorization = `${this.options.tokenType} ${token}`;
            if (typeof token != 'undefined' && token != null) request.headers.Authorization = `Bearer ${token}`;

            return request
        })
    }

    handleResponses() {
        axios.interceptors.response.use((response) => {
            if (response.status == 401 || response.status == 403) {
                console.log('Unauthorized')
                this.storeManager.resetAll()
                this.Vue.$router.push(this.options.authRedirect)
            }
            if (res.error) {
                this.storeManager.resetAll()
                this.options.router.push(this.options.authRedirect)
            }
            return response;
        }, (error) => {
            console.log(error)
            this.storeManager.resetAll()
            this.options.router.push(this.options.authRedirect)
        });
    }
}

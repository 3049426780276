import {StoreLocalStorage} from './store/store-local';
import {StoreSessionStorage} from './store/store-session';

export class AuthStoreManager {

    stores = []

    constructor(Vue, options) {
        this.Vue = Vue
        this.options = options
        this.setStores();
    }

    allStores() {
        return this.stores
    }

    setStores() {
        this.stores = Object.assign([], this.options.tokenStore)
        .map(store => {
            switch (store) {
                case 'vuex':
                    // return this.Vue.prototype.$store
                case 'localStorage':
                    return new StoreLocalStorage(this.Vue, this.options)
                case 'sessionStorage':
                    return new StoreSessionStorage(this.Vue, this.options)
                default:
                    return new StoreLocalStorage(this.Vue, this.options);
            }
        })
    }

    getRoles() {
        return this.stores
            .map((store) => store.getRoles())
            .filter((roles) => roles && roles.length)[0];
    }

    getToken() {
        const token = this.stores
            .map((store) => store.getToken())
            .filter(Boolean)[0];
        return token
    }

    getUser() {
        const user = this.stores
            .map((store) => store.getUser())
            .filter(Boolean)[0];
        return user
    }

    setToken(token) {
        this.stores
            .forEach((store) => {
                store.setToken(token);
            });
    }

    setUser(user) {
        this.stores
            .forEach((store) => {
                store.setUser(user);
            });
    }

    resetAll() {
        this.setToken(null);
        this.setUser(null);
    }

    authorized(roles) {
        if (Array.isArray(roles)) {
            return roles.some(role => this.authorized(role))
        }
        return this.checkRole(roles)
    }

    authorizedByAttribute(attribute) {

      const user = this.getUser();
      if( user == null || user == undefined) return false;
      if( user.details.hasOwnProperty(attribute) == false) return false;

        var val = user.details[attribute];

        if( val == null || val == undefined || val == false) return false;

        return true;
    }

    checkRole(role) {
        if (role) {
            const roles = this.getRoles();
        if (!roles) {
            return false;
        }
        if (Array.isArray(role)) {
            return roles.some((authrole) => role.includes(authrole));
        } else {
            return roles.includes(role);
        }
        } else {
            return !!this.getToken();
        }

    }
}

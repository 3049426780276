export class StoreLocalStorage {
    
    constructor(Vue, options) {
        this.Vue = Vue
        this.options = options
        this.store = window.localStorage;
    }

    
    getRoles() {
        const user = this.getUser()
        return user && this.options.rolesVar && user[this.options.rolesVar];
    }

    getToken() {
        return this.store.getItem(this.options.tokenDefaultName);
    }

    getUser() {
        return JSON.parse(this.store.getItem(this.options.userDefaultName));
    }


    setToken(token) {
        if (token) {
            this.store.setItem(this.options.tokenDefaultName, token);
        } else {
            this.store.removeItem(this.options.tokenDefaultName);
        }
    }

    setUser(user) {
        if (user && Object.keys(user)) {
            this.store.setItem(this.options.userDefaultName, JSON.stringify(user));
        } else {
            this.store.removeItem(this.options.userDefaultName);
        }
    }

}
import axios from 'axios'

export class AuthHttp {
    constructor(Vue, options = {}) {
        this.options = options
    }

    async requestAuthToken(token) {
        try {
            const response = await axios.get(
                `${this.options.hostname}${this.options.requestTokenEndpoint.url}?token=${token}`
            )
            return response.data
        } catch (error) {
            console.log(error)
            return Promise.resolve({ error })
        }
    }

    async login(username, password) {

        let bodyFormData = new FormData();
        bodyFormData.append('grant_type', 'password');
        bodyFormData.append('username', username);
        bodyFormData.append('password', btoa(password));

        let headers = {}
        if (this.options.loginAuth != null) {
            headers = Object.assign(headers, {
                'Authorization': `Basic ${btoa(`${this.options.loginAuth.clientId}:${this.options.loginAuth.clientSecret}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }

        try {
            const response = await axios.post(
                `${this.options.hostname}${this.options.loginEndpoint.url}`,
                bodyFormData,
                {
                    headers: headers
                }
            )

            return response.data
        } catch (error) {
            console.log(error)
            return Promise.resolve({ error })
        }

    }

    async me() {
        try {
            const response = await axios.get(
                `${this.options.hostname}${this.options.fetchEndpoint.url}`
            )
            return response.data
        } catch (error) {
            console.log(error)
            return Promise.resolve({ error })
        }
    }

    async checkToken(token) {
        try {
            const response = await axios.get(
                `${this.options.hostname}${this.options.checkTokenEndpoint.url}?token=${token}`
            )
            return response.data
        } catch (error) {
            return Promise.resolve({ error })
        }
    }
}

<template>

 <transition name="modal">
 <div class="modal" v-if="open">

    <div class="modal__backdrop" @click="open = false"></div>

    <div class="modal__card" :style="{'max-width' : maxwidth }" :class="{'no_padding' : noPadding}">
      <div class="modal__close" @click="open = false"><Svgicon icon="cross" /></div>
     <slot></slot>
    </div>

</div>
</transition>
</template>
  .preview_modal {

    .modal__card {
      max-width: 950px;
      padding: 0;
    }
  }
<script>
import { watch, ref } from 'vue'

export default {
  props: {
    maxwidth: { default: '800px' },
    noPadding: { default: false }
  },
  setup() {
    const open = ref(false)

     watch(open, (currentValue, oldValue) => {

      if( currentValue ) {
        document.getElementsByTagName( 'html' )[0].classList.add('modal_open')
      } else {
        document.getElementsByTagName( 'html' )[0].classList.remove('modal_open')
      }

    });

    return {
      open,
    }
  },
}
</script>

<style scoped>
.no_padding {
  padding: 0;
}

</style>


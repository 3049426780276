import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {

  async create(params) {
    try {
      return await axios.post(
        `${hostname}/products/categories/create`,
        params,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async delete(id) {
    try {
      return await axios.delete(
        `${hostname}/products/categories/${id}/delete`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async update(params) {
    try {
      return await axios.put(
        `${hostname}/products/categories/${params.id}/update`,
        params,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },


  async getById(id) {
    try {
      return await axios.get(
        `${hostname}/products/categories/${id}`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async getAll() {
    let url = `${hostname}/products/categories/all?size=500`
    try {
      return await axios.get(
        url,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },


}

import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

export default {

    async GetAssociatedPersons(organization_id) {
        try {
            let uri = `/pipedrive/organizations/${organization_id}/persons`;
            return await axios.get(`${hostname}${uri}`)
        } catch (error) {
            console.log(error)
        }
    },

}

export class StoreLocalStorage {
    
    constructor(Vue, options) {
        this.Vue = Vue
        this.options = options
        this.store = window.localStorage;
    }

    getUser() {
        return JSON.parse(this.store.getItem(this.options.userDefaultName));
    }

    setUser(user) {
        if (user && Object.keys(user)) {
            this.store.setItem(this.options.userDefaultName, JSON.stringify(user));
        } else {
            this.store.removeItem(this.options.userDefaultName);
        }
    }

}
<template>
    <div class="layout_blank">
        <slot></slot>
    </div>
    <div id="teleport_outside_main"></div>
</template>

<style scoped lang="scss">
.layout_blank {
  width: 100%;
  min-height: 100vh;
  background: color(color6);
}
</style>

import dealsApi from '../../../api/pipedrive/deals'


// state
const state = () => ({
    types: [],
    page: [],
})

// getters
const getters = {
    getStatuses(state) {
        return state.types
    },
    getPage(state) {
        return state.page
    },
}

// actions
const actions = {
    async getStatuses({commit}) {
        var response = await dealsApi.getStatuses();
        commit('setTypes', response.data.data)
    },
    async getPage({commit}, params) {
        var size = (typeof params != 'undefined' && params != null && typeof params.size != 'undefined' && params.size != null) ? params.size : 5
        var page = (typeof params != 'undefined' && params != null && typeof params.page != 'undefined' && params.page != null) ? params.page : 0
        var response = await dealsApi.getPage(page, size)
        commit('setPage', response.data.data)
    },
    async search({commit}, params) {
        var query = params.query
        var size = (typeof params != 'undefined' && params != null && typeof params.size != 'undefined' && params.size != null) ? params.size : 20
        var page = (typeof params != 'undefined' && params != null && typeof params.page != 'undefined' && params.page != null) ? params.page : 0
        var response = await dealsApi.search(query, page, size)
        commit('setPage', response.data.data)
    },

    async getDealsPage({ commit, dispatch }, params) {
      let deals  = await dispatch('getDeals', params)
      commit('setPage', deals)
    },

    async getDeals({ commit }, params) {
      if (typeof params == 'undefined') params = {}
      var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 10
      var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0
      var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
      var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

      if( page > 0) page = page - 1 // Make zero based,

      var response = await dealsApi.getDeals(page, size, sort, query)

      return response.data.data;
    },

}

// mutators
const mutations = {
    setTypes(state, types) {
        state.types = types
    },
    setPage(state, page) {
        state.page = page
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import axios from 'axios'

var hostname = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOSTNAME}`

const config = {}

export default {


  async getQuotes(id, page, size, sort, query) {
    let url = `${hostname}/contractors/${id}/quotes?page=${page}&size=${size}`
    url += ( sort ) ? `&sort=${sort}` : '&sort=created,desc'
    if( query ) url += `&query=${query}`

    try {
      return await axios.get(
        url,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },


  async getById(id) {
    try {
      return await axios.get(
        `${hostname}/contractors/${id}`,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async getContractors(page, size, sort, query, active) {
    let url = `${hostname}/contractors/all?page=${page}&size=${size}`
    url += ( sort ) ? `&sort=${sort}` : '&sort=name,asc'
    if( typeof active == 'boolean') url +=  `&active=${active}`
    if( query ) url += `&query=${query}`

    try {
      return await axios.get(
        url,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async createContractorInEconomic(params) { // Sends Deal to approval at customer
    try {
      return await axios.post(
        `${hostname}/contractors/${params.id}/economic/create`,
        params.body,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },

  async overwriteAccountNumber(params) {
    try {
      return await axios.post(
        `${hostname}/contractors/${params.id}/economic/create?override=true`,
        params.body,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },


  async mailchimpSubscription(params) {
    try {
      return await axios.post(
        `${hostname}/mailchimp/subscribe/contractor`,
        params,
        config
      )
    } catch (error) {
      console.log(error)
    }
  },


}

import productApi from '../../api/products'


// state
const state = () => ({
  product: null,
  productError: null,
  page: null,
  countAll: 0,
  countArchived: 0,
  statistics: null,
})

// getters
const getters = {
  getProduct(state) {
    return state.product
  },
  getProductFieldByIndex: (state) => (idx) => {
    return state.product.fields[idx]
  },
  getPage(state) {
    return state.page
  },
  getStatistics( state ) {
    return state.statistics
  }
}

// actions
const actions = {
  async save({ commit }, params) {
    var response = await productApi.save(params);
    commit('setProduct', response.data.data)
    return response.data.data
  },

  async clone({ commit }, params) {
    var response = await productApi.clone(params.id);
    commit('setProduct', response.data.data)
    return params.id
  },

  async delete({ commit }, id) {
    var response = await productApi.delete(id);
    commit('setProduct', response.data.data)
    commit('removeProductFromPage', id)
  },

  async search({ commit }, query) {
    var response = await productApi.search(query);
    return reponse.data.data
  },

  async updateState({ commit }, params) {  // {id: 3, state: "ARCHIVED"}
    var response = await productApi.update(params);
    commit('setProduct', response.data.data)
    commit('removeProductInPage', response.data.data)

    return response;
  },


  async getProductById({ commit }, params) {
    commit('setProduct', null)
    try {
      var response = await productApi.getProductById(params.id);
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setProduct', response.data.data)
        return response.data.data
      } else {
        commit('setProduct', null)
        commit('setProductError', response.data.data)
      }

    } catch (err) {
      console.log('getProductById', err)
      commit('setProduct', null)
    }

  },


  async getProductPage({ commit, dispatch }, params) {
    let products  = await dispatch('getProducts', params)
    commit('setPage', products)
  },

  async getProducts({ commit }, params) {
    if (typeof params == 'undefined') params = {}
    var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 10
    var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0
    var state = (typeof params.state != 'undefined' && params.state != null) ? params.state : null
    var excludeStates = (typeof params.excludeStates != 'undefined' && params.excludeStates != null) ? params.excludeStates : null
    var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
    var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

    if( page > 0) page = page - 1 // Make zero based,

    var response = await productApi.getProducts(page, size, state, excludeStates, sort, query)

    return response.data.data;
  },

  async statistics({ commit }) {
    var response = await productApi.statistics();
    if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
      commit('setStatistics', response.data.data)
      return response.data.data
    }
  },


}


// mutators
const mutations = {
  setProduct(state, product) {

   if( product !== null) {
      product.fields = product.fields.sort( (a, b) => (a.sortPosition > b.sortPosition) ? 1 : -1)
   }
    state.product = product
  },
  removeProductInPage(state, product) {
    if( state.page == null ) return false;

      const products = state.page.content
      let index = products.map(item => item.id).indexOf(product.id);
      if(index >= 0) { products.splice(index, 1) }
  },

  addProductField(state, { field }) {
    if (state.product.fields == null) state.product.fields = []
    state.product.fields.push(field)
  },

  setProductError(state, error) {
    state.productError = error
  },

  setPage(state, page) {
    state.page = page
  },

  removeProductFromPage(state, productId) {
    const products = state.page.content
    let removeIndex = products.map(item => item.id).indexOf(productId);
    (removeIndex >= 0) && products.splice(removeIndex, 1);
  },

  setStatistics( state, params ) {
    state.statistics = params
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { reactive } from 'vue'

export const state = reactive({
  notifications: [
  ],
});

let count = 0;
const generateId = () => {
  count = count + 1;
  return count
}
export const methods = {
  fire(notification, timeout) {
    notification.id = generateId()
    notification.group = notification.group || ''; // add default group
    state.notifications.push(notification);
    if (typeof timeout != "boolean" || (timeout) ) {
        setTimeout(() => {
            this.removeNotification(notification.id)
          }, timeout || 3000) // default time 3s
    }
    return notification.id
  },

  removeNotification(id) {
    state.notifications.splice(state.notifications.findIndex(n => n.id === id), 1)
  },

  purge() {
      state.notifications.splice(0,state.notifications.length)
  }
};
import { PipedriveStoreManager } from "./pipedrive-store-manager";
import { PipedriveHttp } from "./pipedrive-http";

export const DEFAULT_OPTIONS = {
    appClientId: '',
    appState: 'test',
    appAuthorizeUrl: `/auth/pipedrive`,
    appCallbackUrl: `/auth/pipedrive/callback`,
    appInstallUrl: 'https://oauth.pipedrive.com/oauth/authorize',

    userDefaultName: 'pd_u',
    tokenStore: [
        'localStorage',
        'sessionStorage',
    ],
    authorizeEndpoint: 'https://oauth.pipedrive.com/oauth/authorize',
    tokenEndpoint: 'https://oauth.pipedrive.com/oauth/token',
    meEndpoint: 'https://api.pipedrive.com/v1/me',
    
    guard: false
}

export class Pipedrive {
    constructor(Vue, options = {}) {
        this.options = { ...DEFAULT_OPTIONS, ...options }

        if (!options || !options.router) {
            throw new Error('[pipedrive-plugin] Please initialise plugin with a router instance.');
        }
        
        if (!options || options.appClientId == null || !options.appClientId.length) {
            throw Error(`[pipedrive-plugin] 'appClientId' is a required option`)
        }

        if (!options || options.appCallbackUrl == null || !options.appCallbackUrl.length) {
            throw Error(`[pipedrive-plugin] 'appCallbackUrl' is a required option`)
        }

        this.router = options.router
        this.storeManager = new PipedriveStoreManager(Vue, this.options)
        this.http = new PipedriveHttp(Vue, this.options)
    }
    
    check() {
        let user = this.storeManager.getUser()
        
        if ((typeof user == 'undefined' || user == null) && this.options.guard) {        
            if (!window.location.href.includes(this.options.appAuthorizeUrl) && !window.location.href.includes(this.options.appCallbackUrl)) {
                this.router.push(this.options.appAuthorizeUrl)
            }
        }
        
    }

    // App related urls
    getAppInstallUrl() {
        return `${this.options.appInstallUrl}?client_id=${this.options.appClientId}&redirect_uri=${this.getCallbackUrl()}`
    }

    link() {
        this.router.push(this.options.appAuthorizeUrl)
    }

    getAppAuthorizeUrl() {
        return this.options.appAuthorizeUrl
    } 

    getCallbackUrl() {
        return this.options.appCallbackUrl
    }

    // Authentication
    async authorize(authorizationCode) {
        const response = await this.http.authorize(authorizationCode)
        console.log(response)
        if (response.error) {
            return response
        }
        console.log(response)
        this.storeManager.setUser(response.data)
        return response
    }

    async checkMe() {
        const response = await this.http.me()
        if (response.error) {
            return response
        }
        console.log(response)
        this.storeManager.setUser(response.data)
        return response
    }

    // User data
    getMe() {
        return this.storeManager.getMe()
    }

    sayHello() {
        console.log('Hello from Pipedrive plugin')
    }

    async isLinked() {
        let linked = this.storeManager.isLinked()

        if (!linked) {
            await this.checkMe()
            linked = this.storeManager.isLinked()
        }

        return linked
    }

    reset() {
        this.storeManager.resetAll()
    }
}   
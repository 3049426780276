import quoteApi from '../../api/quotes';

// state
const state = {
    quote: {
      id: null,
      type: null,
      state: "DRAFT",
      status: "UNDEFINED",
      templateId: null,
      title: "",
      subheads: [],
      invoiceDate: null,
      dueDate: new Date().valueOf(),
      paymentTerms: "Netto kontant 5 dage.",
      terms: "",
      pipedriveDeal: null,
      pipedriveDealId: null,
      items: [],
      portal: "3byggetilbud.dk",
    },
    page: null,
    quoteError: null,
    statistics: null,
    events: null,
    consultants: [],
}

// getters
const getters = {
    getQuote(state) {
        return state.quote
    },
    getPage(state) {
      return state.page
    },
    getStatistics( state ) {
      return state.statistics
    },
    getEvents(state) {
      return state.events
    },

    getConsultants( state ) {
      return state.consultants
    },

    getEventsByType: (state) => (type) => {
      if(!state.events) return []
      return state.events.filter(evnt => evnt.type === type)
    },

}

// actions
const actions = {
    async getQuoteById({ commit }, params) {
        commit('setQuote', null)
        try {
            var response = await quoteApi.getById(params.id);
            if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
                commit('setQuote', response.data.data)
                return response.data.data
            } else {
                commit('setQuote', null)
                commit('setQuoteError', response.data.data)
            }

        } catch (err) {
            console.log('getQuoteById', err)
            commit('setQuote', null)
        }

    },

    async getQuoteByUUID({ commit }, uuid) {
      commit('setQuote', null)
      try {
          var response = await quoteApi.getByUUID(uuid);
          if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
              commit('setQuote', response.data.data)
              return response.data
          } else {
              commit('setQuote', null)
              commit('setQuoteError', response.data.data)
              return response.data;
          }

      } catch (err) {
          commit('setQuote', null)
      }

    },

    async getPreviewQuoteByUUID({ commit }, uuid) {
      commit('setQuote', null)
      try {
          var response = await quoteApi.getPreviewByUUID(uuid);
          if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
              commit('setQuote', response.data.data)
              return response.data
          } else {
              commit('setQuote', null)
              commit('setQuoteError', response.data.data)
              return response.data;
          }

      } catch (err) {
          commit('setQuote', null)
      }
    },

    async save({ commit }, params) {
        var response = await quoteApi.save(params);
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
          commit('setQuote', response.data.data)
        }
        return response.data
    },

    async clone({ commit }, params) {
      var response = await quoteApi.clone(params.id);
      commit('setQuote', response.data.data)
      return params.id
    },

    async search({ commit }, query) {
      var response = await quoteApi.search(query);
      return reponse.data.data
    },

    async detachDeal({ commit }, params) {
      var response = await quoteApi.detachDeal(params.id);
      commit('setQuote', response.data.data)

      return response.data;
    },

    async approveDeal({ commit }, params) {
      var response = await quoteApi.approveDeal(params);
      commit('setQuote', response.data.data)
      return response.data;
    },

    async resendDeal({ commit }, params) {
      var response = await quoteApi.resendDeal(params);
      return response.data;
    },

    async resendDealAsText({ commit }, params) {
      var response = await quoteApi.resendDealAsText(params);
      return response.data;
    },

    async acceptDealInternal({ commit }, params) {
      var response = await quoteApi.acceptDealInternal(params);
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setQuote', response.data.data)
      }
      return response.data;
    },

    async acceptDealExternal({ commit }, params) {
      var response = await quoteApi.acceptDealExternal(params);
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setQuote', response.data.data)
      }
      return response.data;
    },

    async getQuotePage({ commit, dispatch }, params) {
      let quotes  = await dispatch('getQuotes', params)
      commit('setPage', quotes)
    },

    async fetchEvents({ commit, dispatch }, params) {
      let response  = await quoteApi.fetchEvents(params)
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setEvents', response.data.data)
      }
      return response;
    },

    async fetchPublicEvents({ commit, dispatch }, params) {
      let response  = await quoteApi.fetchPublicEvents(params)
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit('setEvents', response.data.data)
      }
      return response;
    },

    async createInvoice({ commit }, params) {
      var response = await quoteApi.createInvoice(params);
      commit('setQuote', response.data.data)
      return response.data;
    },

    async importSubscription({ commit }, params) {
      var response = await quoteApi.importSubscription(params);
      commit('setQuote', response.data.data)
      return response.data;
    },

    async paidDeal({ commit }, params) {
      var response = await quoteApi.paidDeal(params);
      commit('setQuote', response.data.data)
      return response.data;
    },

    async getQuotes({ commit }, params) {
      if (typeof params == 'undefined') params = {}
      var size = (typeof params.size != 'undefined' && params.size != null) ? params.size : 15
      var page = (typeof params.page != 'undefined' && params.page != null) ? params.page : 0
      var state = (typeof params.state != 'undefined' && params.state != null) ? params.state : null
      var status = (typeof params.status != 'undefined' && params.status != null) ? params.status : null
      var excludeStates = (typeof params.excludeStates != 'undefined' && params.excludeStates != null) ? params.excludeStates : null
      var excludeStatus = (typeof params.excludeStatus != 'undefined' && params.excludeStatus != null) ? params.excludeStatus : null
      var sort = (typeof params.sort != 'undefined' && params.sort != null) ? params.sort : null
      var query = (typeof params.query != 'undefined' && params.query != null) ? params.query : null

      if( page > 0) page = page - 1 // Make zero based,

      var response = await quoteApi.getQuotes(page, size, state, status, excludeStates, sort, query, excludeStatus)

      return response.data.data;
    },

    async statistics({ commit }) {
        var response = await quoteApi.statistics();
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
            commit('setStatistics', response.data.data)
            return response.data.data
        }
    },

    async fetchAllConsultants({ commit }) {
        var response = await quoteApi.fetchAllConsultants();
        if (response.status >= 200 && response.status < 300) {
            // Check if data key exists
            if( typeof response.data == 'undefined' ) {
              return []
            }
            commit('setConsultants', response.data)
            return response.data
        }
    },

    async updateConsultant({ commit }, params) {
      var response = await quoteApi.updateConsultant(params);
      if (response.status >= 200 && response.status < 300) {
        commit('setQuote', response.data.data)
      }
      return response.data;
    },

}

// mutations
const mutations = {
    setQuote(state, quote) {
      if( quote !== null && quote.subheads ) {
          quote.subheads = quote.subheads.sort( (a, b) => (a.sortPosition > b.sortPosition) ? 1 : -1)
      }
      if( quote !== null && quote.items ) {
        quote.items = quote.items.sort( (a, b) => (a.sortPosition > b.sortPosition) ? 1 : -1)
      }
      state.quote = quote
    },

    removeQuoteInPage(state, quote) {
      if( state.page == null ) return false;

      const quotes = state.page.content
      let index = quotes.map(item => item.id).indexOf(quote.id);
      if(index >= 0) { quotes.splice(index, 1) }
    },

    setQuoteError(state, error) {
      state.quoteError = error
    },

    setPage(state, page) {
      state.page = page
    },

    setStatistics(state, params) {
      state.statistics = params
    },

    setEvents(state, events) {
      state.events = events
    },

    setConsultants(state, consultants) {
      state.consultants = consultants
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


<template>

    <component :is="layout">
      <router-view />
    </component>

</template>

<script>
const default_layout = 'default'

export default {
  async beforeCreate() {
    let isTilbudView = window.location.href.includes('tilbud')
    if (!isTilbudView) {
      let isAuthenticated = await this.$auth.isAuthenticated()
      if (isAuthenticated) {
        let isLinked = await this.$pipedrive.isLinked()
        if (!isLinked && this.$route.path !== '/auth/pipedrive/callback') {
          this.$pipedrive.link()
        }
      }
    }

  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
  },

  created() {

    // Set chosen color theme.
    let theme = localStorage.getItem('colortheme')
    if( theme == 'dark') {
      window.darkTheme = true
      document.getElementsByTagName( 'html' )[0].setAttribute('theme', 'dark')
    } else {
      window.darkTheme = false
    }
  }
}
</script>

import personsApi from '../../../api/pipedrive/persons'


// state
const state = () => ({
    persons: [],
})

// getters
const getters = {

    getPersons(state) {
        return state.persons
    },

    getPrimaryEmails(state) {
      if(state.persons) {
        let emails = [];
        state.persons.data.forEach(person => {
          let obj = {
            name: person.name,
          }

          let primaryEmail = person.email.filter( email => email.primary)
          if( primaryEmail.length > 0 ) {
            obj.primaryEmail = primaryEmail[0].value;
          }
          emails.push(obj);
        });
        return emails;
      }
  },
}

// actions
const actions = {
    async getPersons({commit}) {
        var response = await personsApi.getPersons();
        commit('setPersons', response.data.data)
    },

}

// mutators
const mutations = {

  setPersons(state, persons) {
        state.persons = persons
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
